import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "highlight.js/styles/monokai-sublime.css";
import "../assets/css/loader.css";
import "../assets/css/chat-style.css";
import Applogo from "../assets/images/app-logo.svg";
import Plus from "../assets/images/plus.svg";
import Notebook from "../assets/images/notebook.svg";
import Help from "../assets/images/help.svg";
import Setting from "../assets/images/setting.svg";
import User from "../assets/images/user.svg";
import SmLogo from "../assets/images/sm-logo.svg";
import Orgsearch from "../assets/images/org-search.svg";
import Cross from "../assets/images/cross.svg";
import Pdf from "../assets/images/pdf.svg";
import Mic from "../assets/images/mic.svg";
import MicActive from "../assets/images/mic_active.svg";
import Attach from "../assets/images/attachment.svg";
import PdfUpload from "../assets/images/pdf-upload.svg";
import Dot from "../assets/images/dot.svg";
import Copy from "../assets/images/copy.svg";
import Magic from "../assets/images/magic.svg";
import hljs from "highlight.js";
import "highlight.js/styles/monokai-sublime.min.css";
import ChatMessages from "../components/messages";
import { Link, useNavigate, useSearchParams} from "react-router-dom";
import debounce from "lodash/debounce";
import { create } from "zustand";
import { db, storage } from "../firebaseUtils/firebaseConfig";
import { v4 as uuidv4 } from "uuid";
import {
  collection,
  getDocs,
  getDoc,
  deleteDoc,
  doc,
  updateDoc,
  setDoc,
  serverTimestamp,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import axios from 'axios';


const useTextStore = create((set) => ({
  text: "",
  setText: (newText) => set({ text: newText }),
}));

const SubmitButton = ({ sendMessage , promptDisabled }) => {
  const prompt = useTextStore((state) => state.text);

  return (
    <div
      id="submit-btn"
      onClick={() => sendMessage(prompt)}
      className={`btn-gray btn-txt font-sm px-3 py-1 mcr-2 cpp ${
        prompt.length > 0 ? "yellow txt-white" : ""
      }`}
      style={{ borderRadius: "20px" }}
    >
      <span>Submit</span>
    </div>
  );
};

const TextArea = ({ sendMessage }) => {
  const prompt = useTextStore((state) => state.text);
  const setPrompt = useTextStore((state) => state.setText);
  const textareaRef = useRef(null); 

  useEffect(() => {
    return () => setPrompt("");
  }, [setPrompt]);

  // useEffect(() => {
  //   if (textareaRef.current) {
  //     textareaRef.current.style.height = "auto"; // Reset height
  //     const scrollHeight = textareaRef.current.scrollHeight;
  //     const minHeight = 10; // Set the minimum height in pixels
  //     textareaRef.current.style.height = `${Math.max(scrollHeight, minHeight)}px`;
  //   }
  // }, [prompt]);


  const trimToWordLimit = (text, limit = 3000) => {
    const words = text.split(/\s+/).filter((word) => word.length > 0);
    if (words.length > limit) {
      return words.slice(0, limit).join(" ");
    }
    return text;
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setPrompt(trimToWordLimit(inputText));
  };

  return (
    <textarea
      ref={textareaRef}
      rows={10}
      onChange={handleInputChange}
      onKeyDown={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          sendMessage(prompt);
        }
      }}
      value={prompt}
      className="txt-area bg-input text-white resize-none outline-none"
      placeholder="Enter your prompt here..."
    />
  );
};

const PhotonAI = () => {
  const [activeMode, setActiveMode] = useState("global");
  const [showSidebar, setShowSidebar] = useState(window.innerWidth >= 800);
  const [showDetails, setShowDetails] = useState(true);
  const [addChatDisabled, setAddChatDisabled] = useState(true); 
  const [socket , setSocket] = useState(null);

  const [sideChat, setSideChat] = useState([]);
  const [messages, setMessages] = useState([]);
  const [activeChat, setActiveChat] = useState("");
  const [user, setUser] = useState({});
  const orgAvailable = useRef(true);
  // const [prompt, setPrompt] = useState('');
  const setPrompt = useTextStore((state) => state.setText);
 
  const [promptDisabled, setPromptDisable] = useState(false);
  const [placeHolder, setPlaceHolder] = useState("");
  const [isUploadVisible, setIsUploadVisible] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [inputFileName, setInputFileName] = useState("");
  const [editChatId, setEditChatId] = useState(null);
  const [editTitle, setEditTitle] = useState("");
  const [fileSize, setFileSize] = useState("0.0 MB");
  const [progress, setProgress] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [wsLoading , setWsLoading ] = useState(false)
  const uploadMainRef = useRef(null);
  const [fileLoading, setFileLoading] = useState(false);
  // const [fileUrl , setFileUrl] = useState("")
  const [searchParams] = useSearchParams();

  const recognitionRef = useRef(null);
  const idleTimeoutRef = useRef(null);
  const idleTimeout = 10000;

  const fileUrlRef = useRef("");
  const fileInputRef = useRef(null);
  const modeRef = useRef("global");
  const messageRef = useRef([]);
  const scrollRef = useRef(null);
  const sideRef = useRef(null);
  const navigate = useNavigate();

  const email = useRef(localStorage.getItem("email"));
  const token = useRef(localStorage.getItem("access_token"));
  const refreshToken = useRef(
    encodeURIComponent(localStorage.getItem("refresh_token"))
  );

  // const apiUrl = useRef(process.env.REACT_APP_API_URL);
  // const webUrl = useRef(process.env.REACT_APP_DOMAIN);
  const socketUrl = useRef(process.env.REACT_APP_SOCKET_URL);

  const FirebaseUpload = (name, size) => {
    setFileLoading(true);
    const file = fileInputRef.current?.files[0];
    const orgId = process.env.REACT_APP_ORG_ID;
    const emailBeforeAt = email.current.split("@")[0];
    const uniqueFileName = `${Date.now()}_${file.name}`;
    const current_chat_id = activeChat;

    if (!file) return;

    const storageRef = ref(
      storage,
      `${orgId}/users/${emailBeforeAt}/${uniqueFileName}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.floor(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.error("File upload error:", error);
      },
      async () => {
        setFileLoading(false);
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        fileUrlRef.current = downloadURL;

        const userId = await hashEmail(email.current);
        const chatId = current_chat_id;

        setFileLoading(false);

        const orgId = process.env.REACT_APP_ORG_ID;
        const userDocRef = doc(
          db,
          "organisation",
          orgId,
          "users",
          userId,
          "chats",
          chatId
        );
        await updateDoc(userDocRef, {
          file_url: downloadURL,
          file_name: name,
          file_size: size,
        });
      }
    );
  };

  const invokeCloudRun = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/invoke`);
    } catch (err) {
    }
  };

  // const setQueryParam = (cid) => {
  //   searchParams.set('id', cid);
  //   navigate(`?${searchParams.toString()}`, { replace: true });
  // }

  // const removeQueryParam = () => {
  //   searchParams.delete('id'); 
  //   navigate('/chat', { replace: true }); 
  // };


  const logout = () => {
    localStorage.removeItem("email");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    navigate("/login?message=Logout Success");
  };

  const getModeAuth = async () => {
    try {
      const userId = await hashEmail(email.current);
      const orgId = process.env.REACT_APP_ORG_ID;

      const orgRef = doc(db, "organisation", orgId);
      const orgDocSnap = await getDoc(orgRef);
      const orgData = orgDocSnap.data();

      if (orgData.org_mode === false) {
        orgAvailable.current = false;
      } else {
        const chatDocRef = doc(db, "organisation", orgId, "users", userId);
        const chatDocSnap = await getDoc(chatDocRef);
        const data = chatDocSnap.data();
        if (data.org_mode === false) {
          orgAvailable.current = false;
        }
      }
    } catch (error) {
      console.error("Error retrieving or updating document:", error);
    }
  };

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  async function fetchChats() {
    try {
      const querySnapshot = await getDocs(collection(db, "organisation"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  }

  const hashEmail = async (email) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(email);

    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");

    return hashHex;
  };

  async function checkSession(access_token) {
    const userInfoEndpoint = "https://www.googleapis.com/oauth2/v3/userinfo";
    try {
      const response = await fetch(userInfoEndpoint, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (!response.ok) {
        return null;
      }
      const userInfo = await response.json();
      return userInfo;
    } catch (error) {
      console.error("Error fetching user info:", error);
      return null;
    }
  }

  async function refreshTokenFunc() {
    const refreshEndpoint = "https://oauth2.googleapis.com/token";
    let refreshToken = localStorage.getItem("refresh_token");

    if (!refreshToken) {
      console.error("No refresh token available");
      return null;
    }

    try {
      const response = await fetch(refreshEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          refresh_token: refreshToken,
          grant_type: "refresh_token",
        }),
      });

      if (!response.ok) {
        console.error("Failed to refresh token");
        return null;
      }

      const data = await response.json();
      const newAccessToken = data.access_token;
      localStorage.setItem("access_token", newAccessToken);
      token.current = newAccessToken;
      return newAccessToken;
    } catch (error) {
      console.error("Error refreshing token:", error);
      return null;
    }
  }

  async function validSession() {
    let accessToken = localStorage.getItem("access_token");
    const userInfo = await checkSession(accessToken);

    if (!userInfo) {
      accessToken = await refreshTokenFunc();

      if (!accessToken) {
        console.error("Unable to refresh token");
        window.location.href = `${process.env.REACT_APP_DOMAIN}/login`;
        return null;
      }
    }

    return accessToken;
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    if (uploadMainRef.current) {
      uploadMainRef.current.style.borderColor = "#4caf50"; // Highlight border
    }
  };

  const handleDragLeave = () => {
    if (uploadMainRef.current) {
      uploadMainRef.current.style.borderColor = "rgba(159, 171, 202, 0.5)"; // Reset border
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    if (uploadMainRef.current) {
      uploadMainRef.current.style.borderColor = "rgba(159, 171, 202, 0.5)"; // Reset border
    }

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(files[0]);
      fileInputRef.current.files = dataTransfer.files;

      const file = files[0];
      const file_size = (file.size / (1024 * 1024)).toFixed(2) + " MB";
      setInputFileName(file.name);
      setFileLoading(true);
      setShowUpload(true) 
      FirebaseUpload(file.name, file_size);
      setFileSize(file_size);
    }
  };

  const handleRecordClick = () => {
    const recognition = recognitionRef.current;
    if (!recognition) return;

    if (!isRecording) {
      recognition.start();
      setIsRecording(true);
      resetIdleTimeout();
    } else {
      recognition.stop();
      setIsRecording(false);
      clearTimeout(idleTimeoutRef.current);
    }
  };

  const addCopyButtons = () => {
    const codeBlocks = document.querySelectorAll("pre");
    codeBlocks.forEach((block) => {
      if (!block.querySelector(".custom-rect")) {
        const copy_div = document.createElement("div");
        const copy_img = document.createElement("img");
        copy_img.src = Copy;
        copy_div.className = "custom-rect position-copy";
        copy_div.addEventListener("click", () => copyCode(block, copy_div));
        copy_div.appendChild(copy_img);
        block.appendChild(copy_div);
      }
    });
  };

  const copyCode = (block, copy_div) => {
    const code = block.textContent;
    navigator.clipboard
      .writeText(code)
      .then(() => {
        const img = copy_div.querySelector("img");
        if (img) {
          img.src = Magic;
        }
        setTimeout(() => {
          if (img) {
            img.src = Copy;
          }
        }, 500);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const resetIdleTimeout = () => {
    clearTimeout(idleTimeoutRef.current);
    idleTimeoutRef.current = setTimeout(() => {
      if (isRecording) {
        recognitionRef.current.stop(); // Stop recording if idle time exceeds limit
      }
    }, idleTimeout);
  };

  const handleTitleChange = (event, uid) => {
    if (event.key === "Enter") {
      updateChatTitle(uid, event.target.value);
    }
  };

  // const changeActiveTitle = () => {
  //     document.getElementById(`span-${activeChat}`).value = prompt
  // }

  const toggleEdit = (uid, title) => {
    setEditChatId(uid);
    setEditTitle(title);
  };

  async function fetchUserInfoEmail(email) {
    await validSession();
    try {
      const userId = await hashEmail(email);
      const orgId = process.env.REACT_APP_ORG_ID;
      const userDocRef = doc(db, "organisation", orgId, "users", userId);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userInfo = userDoc.data();
        setUser(userInfo);
      } else {
        console.error("No such user document found in Firestore");
      }
    } catch (error) {
      console.error("Error fetching user info from Firestore:", error);
    }
  }

  function convertData(doc) {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      timestamp: data.timestamp?.toDate().toString(),
      updated_at: data.updated_at?.toDate().toString(),
    };
  }

  async function getChat(mode, user_email, cur_token, refresh_token, first=false) {
    await validSession();

    setPromptDisable(true);
    setPlaceHolder("Processing...");

    const userId = await hashEmail(user_email);
    const orgId = process.env.REACT_APP_ORG_ID;

    const chatsRef = collection(
      db,
      "organisation",
      orgId,
      "users",
      userId,
      "chats"
    );
    const chatsQuery = query(
      chatsRef,
      where("mode", "==", mode),
      orderBy("updated_at", "desc")
    );

    const querySnapshot = await getDocs(chatsQuery);
    const chats = querySnapshot.docs.map((doc) => convertData(doc));

    if (chats?.length > 0) {
      loadChatMessages(chats[0].uid,first);
      setActiveChat(chats[0].uid);
    } else {
      addChat();
    }

    // if(searchParams.get('id')){

    // }else{
    //     // console.log("id not present")
    // }
    setSideChat(chats);
    setPromptDisable(false);
  }

  // const openVirtualChat = () => {
  //   removeQueryParam()
  //   setMessages([])
  //   setActiveChat('')
  //   setShowDetails(true)
  // }

 
  async function loadChatMessages(chat_id , first=false) {
    
    await validSession();
    // setQueryParam(chat_id)
    if(socket !== null){
      socket.close()
      setWsLoading(false)
      setSocket(null)
    }
    setEditChatId("")

    setPromptDisable(true);
    setActiveChat(chat_id);

    const orgId = process.env.REACT_APP_ORG_ID;
    const userId = await hashEmail(email.current);

    // console.log(orgId , userId , chat_id) 

    try {
      const chatDocRef = doc(
        db,
        "organisation",
        orgId,
        "users",
        userId,
        "chats",
        chat_id
      );
      const chatDoc = await getDoc(chatDocRef);
      if (chatDoc.exists()) {
        const chatInfo = chatDoc.data();
        if (chatInfo && chatInfo.file_url !== "") {
          // setShowDetails(true);
          setShowUpload(true);
          setFileSize(chatInfo.file_size);
          setInputFileName(chatInfo.file_name);
          fileUrlRef.current = chatInfo.file_url;
        } else {
          setShowUpload(false);
          // setShowDetails(false);
        }
      } else {
        setShowUpload(false);
        console.error("No such user document found in Firestore");
      }
    } catch (error) {
      console.error("Error fetching messages from Firebase:", error);
    }

    try {
      const messagesCollectionRef = collection(
        db,
        "organisation",
        orgId,
        "users",
        userId,
        "chats",
        chat_id,
        "messages"
      );

      const messagesQuery = query(
        messagesCollectionRef,
        orderBy("timestamp", "asc")
      );
      const querySnapshot = await getDocs(messagesQuery);

      const msgData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (msgData?.length > 0) {
        setShowDetails(false);
        if(first === true){
          setAddChatDisabled(false)
        }
      } else {
        setShowDetails(true);
        if(first === true){
          setAddChatDisabled(true)
        }
      }

      // console.log("msgData", msgData);

      setMessages(msgData);
      messageRef.current = msgData;

      // hljs.highlightAll();
      setPromptDisable(false);
      setTimeout(addCopyButtons, 500);

      setTimeout(() => {
        if (scrollRef.current) {
          scrollRef.current.scrollTo({
            top: scrollRef.current.scrollHeight,
          });
        }
      }, 500);
    } catch (error) {
      setPromptDisable(false);
      console.error("Error fetching messages from Firebase:", error);
    }
  }

  const addChat = async () => {
    try {
      if (!["org", "global"].includes(modeRef.current)) {
        // return { data: "", status: 401 };
        return;
      }

      if(socket !== null){
        socket.close()
        setWsLoading(false)
        setSocket(null)
      }

      setAddChatDisabled(true)
      RemoveUpload()

      const userId = await hashEmail(email.current);
      const chatId = uuidv4();

      const data = {
        uid: chatId,
        user_id: userId,
        email: email,
        title: "New Chat",
        file_url: "",
        file_name: "",
        file_size: "",
        mode: modeRef.current,
        timestamp: serverTimestamp(),
        updated_at: serverTimestamp(),
      };

      const orgId = process.env.REACT_APP_ORG_ID;
      const userDocRef = doc(
        db,
        "organisation",
        orgId,
        "users",
        userId,
        "chats",
        chatId
      );

      await setDoc(userDocRef, data);

      setActiveChat(chatId);
      setPrompt("");
      getChat(
        modeRef.current,
        email.current,
        token.current,
        refreshToken.current
      );
    } catch (error) {
      console.error("Error adding chat in Firestore:", error);
    }
  };

  const deleteChat = async (uid) => {
    await validSession();
    try {
      if (activeChat !== uid) {
        setSideChat((prevSideChat) =>
          prevSideChat.filter((chat) => chat.uid !== uid)
        );
        const userId = await hashEmail(email.current);
        const orgId = process.env.REACT_APP_ORG_ID;

        await deleteDoc(
          doc(db, "organisation", orgId, "users", userId, "chats", uid)
        );
      }
    } catch (error) {
      console.error("Error deleting chat :", error);
    }
  };

  const updateChatTitle = async (uid, title) => {
    await validSession();
    try {
      setSideChat((prevChats) =>
        prevChats.map((chat) => {
          return chat.uid === uid ? { ...chat, title: title } : chat;
        })
      );

      setEditChatId("");
      setEditTitle("");

      const orgId = process.env.REACT_APP_ORG_ID;
      const userId = await hashEmail(email.current);

      const chatDocRef = doc(
        db,
        "organisation",
        orgId,
        "users",
        userId,
        "chats",
        uid
      );

      await updateDoc(chatDocRef, {
        title: title,
        updated_at: serverTimestamp(),
      });
    } catch (error) {
      console.error("Error updating chat title in Firestore:", error);
    }
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const changeActiveMode = (mode) => {
    modeRef.current = mode;
    setActiveMode(mode);
    setAddChatDisabled(true)
    setSideChat([]);
    setMessages([])
    fileUrlRef.current = ""
    // removeQueryParam()
    setShowDetails(true)
    setActiveChat('')
    getChat(
        modeRef.current,
        email.current,
        token.current,
        refreshToken.current,
        true
    )
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // fileInputRef.current = file;
    if (file) {
      const file_size = (file.size / (1024 * 1024)).toFixed(2) + " MB";
      setFileSize(file_size);
      setInputFileName(file.name);
      setShowUpload(true);
      FirebaseUpload(file.name, file_size);
    } else {
      setShowUpload(false);
    }
  };

  const clearBucketFile = async () => {
    const userId = await hashEmail(email.current);
    const chatId = activeChat;
    const orgId = process.env.REACT_APP_ORG_ID;

    const userDocRef = doc(db,"organisation",orgId,"users",userId,"chats",chatId);

    await updateDoc(userDocRef, {
        file_url: "",
        file_name : "",
        file_size : "",
        });
  }

  const clearUpload = async () => {
    fileUrlRef.current = "";

    setInputFileName("");
    setFileSize("");
    setShowUpload(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    await clearBucketFile()
  };

  const RemoveUpload = () => {
    setInputFileName("");
    setFileSize("");
    fileUrlRef.current = ""
    setShowUpload(false)
  }

  // const autoResize = (element) => {
    // element.style.height = 'auto';
    // element.style.height = element.scrollHeight + 'px';
  // };

  // const handlePromptChange = (e) => {
  //   setPrompt(e.target.value);
  //   autoResize(e.target);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('Submitting message:', prompt);
  };

  const debouncedScrollToBottom = debounce(() => {
    scrollToBottom();
  }, 100);

  const sendMessage = async (prompt) => {
    if (promptDisabled === true) {
      return;
    }

    if (prompt.length <= 0) {
      return;
    }

    setPrompt("");
    setPromptDisable(true);
    setShowDetails(false);

    const codeBlocks = document.querySelectorAll("pre code");
    codeBlocks.forEach((block) => {
      hljs.highlightElement(block);
    });

    // if(!searchParams.get('id')){
    //     addChat()
    // }

    if (messages.length <= 0) {
      setSideChat((prevChats) =>
        prevChats.map((chat) => {
          return chat.uid === activeChat
            ? { ...chat, title: prompt_now }
            : chat;
        })
      );
    }

    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }

    const prompt_now = prompt;
    const messagesWithUser = [
      ...messageRef.current,
      { role: "user", content: prompt_now },
    ];
    messageRef.current = messagesWithUser;
    setMessages(messagesWithUser);

    const userId = await hashEmail(email.current);
    const orgId = process.env.REACT_APP_ORG_ID;

    const chatDocRef = doc(
      db,
      "organisation",
      orgId,
      "users",
      userId,
      "chats",
      activeChat
    );

    await updateDoc(chatDocRef, {
      updated_at: serverTimestamp(),
    });

    const ws = new WebSocket(`wss://${socketUrl.current}/gemini`);
    let chunk_data = "";

    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
        behavior: "smooth",
      });
    }

    ws.onopen = () => {
      const socket_data = {
        type: modeRef.current,
        prompt: prompt_now,
        chat_uid: activeChat,
        email: email.current,
        token: token.current,
        refresh_token: refreshToken.current,
        file_url: fileUrlRef.current,
      };
      ws.send(JSON.stringify(socket_data));
      setWsLoading(true)
      // count = 0;
    };

    ws.onmessage = (event) => {
      // hljs.highlightAll()
      const chunk = event.data;
      // count += 1;

      if (chunk === "1/END") {
        setPromptDisable(false);
        setWsLoading(false)
        setAddChatDisabled(false)

        // hljs.highlightAll();
        return;
      } else if (chunk === "1/SESSIONEXP") {
        setPromptDisable(false);
        setWsLoading(false)
        setAddChatDisabled(false)

        const updatedMessage = [...messageRef.current];
        updatedMessage[updatedMessage.length - 1] = {
          role: "model",
          content: "Session Expired please login again",
        };
        messageRef.current = updatedMessage;
        setMessages(updatedMessage);
      } else if (chunk === "1/ERROR") {
        setPromptDisable(false);
        setWsLoading(false)
        setAddChatDisabled(false)

        const updatedMessage = [...messageRef.current];
        updatedMessage[updatedMessage.length - 1] = {
          role: "model",
          content: "Error generating response please try rephrasing the prompt.",
        };
        messageRef.current = updatedMessage;
        setMessages(updatedMessage);
      } else if (chunk.startsWith("1/TOKEN-")) {
        setWsLoading(false)
        setPromptDisable(false);
        setAddChatDisabled(false)

        const accessToken = chunk.substring("1/TOKEN-".length);
        localStorage.setItem("access_token", accessToken);
        token.current = accessToken;
      } else if (chunk === "1/REGEN"){
        const updatedMessage = [...messageRef.current];
        updatedMessage[updatedMessage.length - 1] = {
          role: "model",
          content: "Regenerating...",
        };
        messageRef.current = updatedMessage;
        setMessages(updatedMessage);
      }else {
        if (chunk_data === "") {
          chunk_data += chunk;

          const updatedMessage = [
            ...messageRef.current,
            { role: "model", content: chunk_data },
          ];
          messageRef.current = updatedMessage;
          setMessages(messageRef.current);
        } else {
          chunk_data += chunk;

          const updatedMessage = [...messageRef.current];
          updatedMessage[updatedMessage.length - 1] = {
            role: "model",
            content: chunk_data,
          };
          messageRef.current = updatedMessage;
          setMessages(messageRef.current);
        }
      }

      addCopyButtons();
      // hljs.highlightAll();
    };
    setSocket(ws)
  };

  useEffect(() => {
    // hljs.highlightAll();
    fetchChats();
    getModeAuth();
    debouncedScrollToBottom();
  }, [messages, debouncedScrollToBottom]);

  useEffect(() => {
    if (!email.current || !token.current || !refreshToken.current) {
      logout();
    }
    invokeCloudRun()
    // fetchUserInfo(token.current)
    fetchUserInfoEmail(email.current);
    getChat(
      modeRef.current,
      email.current,
      token.current,
      refreshToken.current,
      true
    );
  }, []);

  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      const SpeechRecognition = window.webkitSpeechRecognition;
      const recognition = new SpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;
      recognitionRef.current = recognition;

      recognition.onresult = (event) => {
        const result = event.results[0][0].transcript;
        setPrompt(result);
        resetIdleTimeout();
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error:", event.error);
        clearTimeout(idleTimeoutRef.current);
      };

      recognition.onspeechend = resetIdleTimeout; // Reset idle timeout when speech ends

      recognition.onend = () => {
        setIsRecording(false);
        clearTimeout(idleTimeoutRef.current);
      };
    } else {
      alert("Speech Recognition Not Supported");
    }
  }, [setPrompt]);

  return (
    <div className="black" style={{ height: "100vh" }}>
      <div
        className="d-flex"
        id="main-ct"
        style={{ height: "100vh", padding: "10px" }}
      >
        <div
          className=" mt-3 px-3 d-flex justify-content-between"
          style={{
            position: "fixed",
            bottom: "30px",
            left: "12px",
            zIndex: 10001,
          }}
        >
          <div onClick={toggleSidebar} className="custom-circle">
            <img width="16px" src={Notebook} alt="Toggle Sidebar" />
          </div>
          <div className="custom-circle d-none">
            <img src={Help} alt="Help" />
          </div>
          <div className="custom-circle d-none">
            <img src={Setting} alt="Settings" />
          </div>
        </div>

        {showSidebar && (
          <div
            id="side"
            ref={sideRef}
            className="side md-black rounded-15 flex-column p-2 shadow-sm bg-white"
          >
            <div className="d-flex align-items-center justify-content-center mb-3 mt-4" >
              <img
                className="mcr-1"
                height="30px"
                src={Applogo}
                alt="App Logo"
              />
            </div>
            <hr className="hr-color" />
            <div
              onClick={addChatDisabled === true ? undefined : addChat}
              className={`txt-white p-2 rounded-8 mt-3 zoom-div d-flex green-hover align-items-center cpp`}
              style={{
                height: '40px',
                cursor: addChatDisabled === true ? 'not-allowed' : 'pointer',
              }}
            >
              <img
                style={{ margin: '0px 4px' }}
                src={Plus}
                height="14px"
                alt="Add Chat"
              />
              <span className="mcl-1 txt-light font-13">New Chat</span>
            </div>

            <div className="mt-3" style={{ textAlign: "left" }}>
              <span className="txt-light font-sm text-left">Recent</span>
            </div>

            <div
              style={{
                overflowY: "scroll",
                maxHeight: "calc(100vh - 270px)",
                minHeight: "calc(100vh - 270px)",
                boxShadow: "inset 0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
              className="side-chat-scroll"
            >
              <ul
                id="scroll-chat"
                className="nav nav-pills flex-column mb-auto mt-2"
              >
                {sideChat.map((chat, index) => (
                  <li key={index} className="w-100 nav-item hide-scrollbar">
                    <a 
                      className={`w-100 d-flex justify-content-between nav-link cpp green-hover txt-light rounded-8 mt-2 position-r hover-white ${
                        activeChat === chat.uid ? "active-chat txt-white" : ""
                      }`}
                      id={chat.id}
                      style={{ height: "40px" , padding : "0 15px 0 0"  }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.querySelector(
                          ".three-dots"
                        ).style.display = "inline")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.querySelector(
                          ".three-dots"
                        ).style.display = "none")
                      }
                    >
                      {editChatId === chat.uid && (
                        <input
                          type="text"
                          className={`edit-input chat-input-open`}
                          value={editTitle}
                          id={`inpt-${chat.uid}`}
                          onChange={(e) => setEditTitle(e.target.value)}
                          onKeyDown={(e) => handleTitleChange(e, chat.uid)}
                        />
                      )}

                      <span  onClick={() => {
                            RemoveUpload();  
                            loadChatMessages(chat.uid);  
                          }}
                        className="font-13 d-flex align-items-center"
                        style={{ flex: 1 , paddingLeft : "16px"}}
                        id={`span-${chat.uid}`}
                      >
                        {chat.title.length > 22
                          ? `${chat.title.substring(0, 22)}...`
                          : chat.title}
                      </span>

                      <div
                        className="menu-container dropdown d-flex align-items-center"
                        style={{ position: "relative" }}
                      >
                        <img
                          src={Dot}
                          height="14"
                          width="10"
                          alt="three dots"
                          className="three-dots"
                          style={{ cursor: "pointer", display: "none" }}
                          data-bs-toggle="dropdown"
                        />

                        <ul
                          className="dropdown-menu"
                          style={{ position: "absolute", right: 0 }}
                        >
                          <li
                            className="dropdown-item"
                            onClick={() => toggleEdit(chat.uid, chat.title)}
                          >
                            Edit
                          </li>
                          {chat.uid !== activeChat && (
                            <li
                              className="dropdown-item"
                              id={`delete-${chat.uid}`}
                              onClick={() => deleteChat(chat.uid)}
                            >
                              Delete
                            </li>
                          )}
                        </ul>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className="w-100">
          <div
            className="custum-card d-flex justify-content-center w-100"
            style={{ height: "90%" }}
          >
            <div
              className="d-flex w-100 align-items-center justify-content-end"
              style={{ position: "absolute", left: "-40px" }}
            >
              {orgAvailable.current === true && (
                <>
                  <span className="txt-light mcr-1 font-xsm">Switch to:</span>
                  <div
                    className="d-flex p-1"
                    style={{
                      border: "1px solid #D3DDFC",
                      borderRadius: "20px",
                    }}
                  >
                    <div
                      id="global"
                      onClick={() => changeActiveMode("global")}
                      className={`font-xsm px-3 py-1 cpp ${
                        activeMode === "global"
                          ? "yellow txt-white"
                          : "disable-txt"
                      }`}
                      style={{ borderRadius: "20px" }}
                    >
                      Global
                    </div>
                    <div
                      id="org"
                      onClick={() => changeActiveMode("org")}
                      className={`font-xsm px-3 py-1 cpp ${
                        activeMode === "org"
                          ? "yellow txt-white"
                          : "disable-txt"
                      }`}
                      style={{ borderRadius: "20px" }}
                    >
                      Org mode
                    </div>
                  </div>
                  <hr
                    style={{
                      height: "30px",
                      width: "1px",
                      backgroundColor: "rgba(211, 221, 252, 1)",
                      border: "none",
                      margin: "0 15px",
                    }}
                  />
                </>
              )}

              <div className="dropdown">
                <div
                  className="custom-circle"
                  style={{ overflow: "hidden" }}
                  id="userDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    height="40px"
                    id="user_img"
                    src={user.picture ? user.picture : User}
                    alt="User"
                  />
                </div>
                <ul
                  className="dropdown-menu dropdown-menu-end mt-2"
                  aria-labelledby="userDropdown"
                >
                  <li>
                    <a
                      className="dropdown-item font-md"
                      href="#"
                      onClick={logout}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div
              ref={scrollRef}
              className="chat-messages mt-5 txt align-items-center justify-content-center responsive-message"
              id="scroll-div"
              style={{ overflowY: "scroll" }}
            >
              <div className="d-flex w-100 h-100 flex-column ">
                {showDetails ? (
                  <div
                    id="details"
                    className="message mb-4 fs-17 d-flex align-items-center justify-content-center flex-column w-100 h-100"
                  >
                    <div
                      className="p-3 md-black rounded-15"
                      style={{ borderBottom: "2px solid #59DCC5" }}
                    >
                      <img className="" height="32px" src={SmLogo} alt="logo" />
                    </div>

                    <span
                      className="green-txt mt-3 font-xl font-semibold"
                      id="user-name"
                    >
                      {user.name ? `Hi, ${user.name}` : "Hi,"}
                    </span>
                    <span className="txt-light mt-2 font-lg font-bold">
                      Can I help you with anything?{" "}
                    </span>
                    <span className="mt-2 font-sm" style={{ color: '#9fabca' }}>
                      {" "}
                      Photon is ready to assist you with anything you need,
                      including
                    </span>
                    <span className="font-sm" style={{ color: '#9fabca' }}>
                      {" "}
                      queries answered and support for your regular work tasks.
                    </span>

                    {orgAvailable.current &&
                      (modeRef.current === "org" ? (
                        <div
                          id="org-txt"
                          className="mt-5 w-100 d-flex align-items-center justify-content-start rounded-3 md-black"
                          style={{ padding: "12px", marginBottom: "195px" }}
                        >
                          <img
                            className="mcr-1 mcl-2"
                            height="24px"
                            src={Magic}
                            alt="magic"
                          />
                          <span className="txt-white font-md font-semibold">
                            Organisation mode:
                            <span className="gray-dark font-sm">
                              Enter your prompt and get direct results from your
                              organisation database.
                            </span>
                          </span>
                        </div>
                      ) : (
                        <div
                          id="global-txt"
                          className="mt-5 w-100 d-flex align-items-center justify-content-start rounded-3 md-black"
                          style={{ 
                            padding: "12px",
                            // marginTop: !orgAvailable.current ? "500px" : "0px",
                             }}
                        >
                          <img
                            className="mcr-1 mcl-2"
                            height="24px"
                            src={Magic}
                            alt="magic"
                          />
                          <span className="txt-white font-md font-semibold">
                            Organisation mode :
                            <span className="gray-dark font-sm">
                              &nbsp; Switch ‘Org mode’ to get direct results
                              from your organisation database.
                            </span>
                          </span>
                        </div>
                      ))}

                    {modeRef.current === "global" && (
                      <div
                        id="upload-main"
                        className="p-3 w-100 d-flex align-items-center justify-content-center p-2 rounded-3 md-black"
                        style={{ 
                          height: "180px",
                          marginTop: orgAvailable.current ? "20px" : "50px", }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center flex-column"
                          style={{ flex: "0.32" }}
                        >
                          <img src={Orgsearch} height="40px" alt="search" />
                          <span className="txt-light font-md mt-3 font-semibold">
                            Drop your document
                          </span>
                          <span className="txt-light font-md font-semibold">
                            to search within it.
                          </span>
                        </div>
                        <div
                          className="p-3 d-flex flex-column align-items-center justify-content-center rounded-15"
                          style={{
                            flex: "0.68",
                            border: "1px dashed rgba(159, 171, 202, 0.5)",
                            position: "relative",
                          }}
                        >
                          {inputFileName && showDetails ? (
                            <div
                              id="upload-details"
                              className="w-100 d-flex flex-column align-items-center justify-content-center"
                              style={{ position: "relative" }}
                            >
                              <div
                                onClick={() => clearUpload()}
                                className="p-absolute cpp"
                                style={{
                                  position: "absolute",
                                  top: "-12px",
                                  right: "-5px",
                                }}
                              >
                                <img height="10px" src={Cross} alt="clear" />
                              </div>
                              <img height="52px" src={Pdf} alt="pdf" />
                              <span
                                id="file-name"
                                className="font-sm mt-2 txt-light"
                              >
                                {inputFileName}
                              </span>
                              <span
                                id="file-size"
                                className="font-xsm gray-txt"
                              >
                                File Size: {fileSize}
                              </span>
                              {fileLoading && (
                                <div
                                  id="main-progress"
                                  className="mt-3 d-flex align-items-center justify-content-between w-75"
                                  style={{
                                    marginRight: "10px",
                                    marginLeft: "10px",
                                  }}
                                >
                                  <div
                                    id="progress-bar"
                                    className="white w-100 rounded-15"
                                    style={{ height: "6px" }}
                                  >
                                    <div
                                      className="yellow rounded-15"
                                      style={{
                                        width: `${progress}%`,
                                        height: "6px",
                                      }}
                                    ></div>
                                  </div>
                                  <span
                                    id="progress-percentage"
                                    style={{ marginLeft: "10px" }}
                                    className="gray-txt font-ssxs"
                                  >
                                    {progress}%
                                  </span>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div
                              ref={uploadMainRef}
                              onDragOver={handleDragOver}
                              onDragLeave={handleDragLeave}
                              onDrop={handleDrop}
                              id="upload-input"
                              className="d-flex flex-column justify-content-center align-items-center w-100"
                              style={{ height: "90%" }}
                            >
                              <img height="75px" src={PdfUpload} alt="upload" />
                              <label
                                htmlFor="fileInput"
                                className="font-sm mt-3 txt-light"
                              >
                                <span
                                  onClick={handleClick}
                                  className="green-txt underline cpp font-semibold"
                                >
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </label>
                              <span className="font-xsm gray-txt">
                                Maximum file size 10 MB.
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <ChatMessages loading={wsLoading} messages={messages} user={user} />
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center h-[10%] px-16">
            <div className="w-full">
              <form onSubmit={handleSubmit}>
                <input
                  type="file"
                  name="pdf"
                  accept="application/pdf"
                  onChange={handleFileUpload}
                  ref={fileInputRef}
                  style={{ display: "none" }}
                />

                <div className="relative flex justify-end items-center mb-3">
                  {isUploadVisible && (
                    <div className="absolute flex items-center bg-gray-800 rounded-lg p-2">
                      <p className="m-0 text-white">{inputFileName}</p>
                      <div
                        onClick={clearUpload}
                        className="ml-2 p-1 hover:bg-gray-700 rounded-full"
                      >
                        <img height="52px" src={Cross} alt="PDF" />
                      </div>
                    </div>
                  )}

                  <div className="d-flex zoom-div align-items-center justify-content-center">
                    <div
                      className="form-control bg-input custom-input fs-17 shadow-sm d-flex align-items-center position-relative"
                      style={{ border: "none", width: "75%" }}
                    >
                      {showUpload && (
                        <>
                          <div
                            id="upload-div"
                            className="upload-page"
                            style={{ position: "absolute", zIndex: "1" }}
                          >
                            {fileLoading ? (
                              <div
                                id="main-progress"
                                className="mt-2 d-flex align-items-center justify-content-between w-75"
                                style={{
                                  marginRight: "10px",
                                  marginLeft: "10px",
                                }}
                              >
                                <div
                                  id="progress-bar"
                                  className="white w-100 rounded-15"
                                  style={{ height: "6px" }}
                                >
                                  <div
                                    className="yellow rounded-15"
                                    style={{
                                      width: `${progress}%`,
                                      height: "6px",
                                    }}
                                  ></div>
                                </div>
                                <span
                                  id="progress-percentage"
                                  style={{ marginLeft: "10px" }}
                                  className="gray-txt font-ssxs"
                                >
                                  {progress}%
                                </span>
                              </div>
                            ) : (
                              <>
                                <p
                                  id="upload-inputFileName"
                                  style={{ marginBottom: "0" }}
                                >
                                  {inputFileName?.length > 22
                                    ? `${inputFileName?.slice(0, 22)}...`
                                    : inputFileName}
                                </p>
                                <div className="mcl-2 cpp">
                                  <img
                                    onClick={clearUpload}
                                    alt="clear"
                                    width="10px"
                                    src={Cross}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}

                      <TextArea sendMessage={sendMessage}></TextArea>

                      <div
                        className="position-absolute top-0 d-flex align-items-center pr-3 h-100"
                        style={{ right: 0, gap: "20px" }}
                      >
                        {modeRef.current === "global" && (
                          <img
                            height="20px"
                            src={Attach}
                            onClick={handleClick}
                            alt="Attach"
                            className="mr-2 cpp"
                          />
                        )}
                        <img
                          width={isRecording ? "20px" : "20px"}
                          height="20px"
                          src={isRecording ? MicActive : Mic}
                          onClick={handleRecordClick}
                          alt="Mic"
                          className="cpp"
                        />
                        <SubmitButton sendMessage={sendMessage} promptDisabled={promptDisabled}></SubmitButton>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotonAI;
